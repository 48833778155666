import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (active) {
    try {
        const params = { active };
        const res = await axios.get(Env.apiPath + "listStudentId", { params });
        if (res.data.error) {
            alert(res.data.error);
            return null;
        }
        return res.data.list;
    } catch (err) {
        alert(err);
    }
}