import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (year, month, studentIds) {
    try {
        const toSend = new FormData();
        toSend.append("year", year);
        toSend.append("month", month);
        toSend.append("studentIds", studentIds);
        const res = await axios.post(Env.apiPath + "listPractice", toSend);
        if (res.data.error) {
            alert(res.data.error);
            return null;
        }
        return res.data.list;
    } catch (err) {
        alert(err);
    }
}