import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (classId, year, month, withCheck, teacherId) {
    try {
        const params = { classId, year, month, withCheck, teacherId };
        const res = await axios.get(Env.apiPath + "listSession", { params });
        if (res.data.error) {
            alert(res.data.error);
            return null;
        }
        return res.data.list;
    } catch (err) {
        alert(err);
    }
}