<template>
<div class="pt-32 flex flex-col items-center">
    <van-cell-group class="md:w-2/3 w-full mb-8 shadow-md p-8" inset>
        <div class="text-center pb-8">
            <van-tag class="text-xl px-2 mx-4" round type="primary">{{dateRange[0]}}</van-tag>
            <span class="text-blue-500 text-2xl"> ↔️ </span>
            <van-tag class="text-xl px-2 mx-4" round type="primary">{{dateRange[1]}}</van-tag>
        </div>
        <div class="flex">
            <div class="pr-12">
                <div class="text-gray-500 text-sm">此页为近期团员的出勤与作业概况。我们要求团员每周应完成5次作业。自我训练与坚持是通向成功与健康的最基本前提，希望大家共勉之！</div>
                <br />
                <div class="text-gray-500 text-sm">This page shows the overall status of our recent attendance and homework completion. We request every team member to practice 5 homeworks every week. Self-discipline and basic perseverance are the first premises to any success and health, let's work hard together and don't leave any regrets!</div>
            </div>
            <div class="text-sm text-white">
                <div class="bg-green-400 w-40 text-center h-8 pt-1">出勤 Presence</div>
                <div class="bg-yellow-600 w-40 text-center h-8 pt-1">迟到 Tardy Presence</div>
                <div class="bg-red-600 w-40 text-center h-8 pt-1">请假 Excused Absence</div>
                <div class="bg-gray-800 w-40 text-center h-8 pt-1">无故旷课 Truancies</div>
            </div>
        </div>
    </van-cell-group>

    <div class="flex flex-wrap justify-center">
        <van-cell-group class="md:w-2/5 w-full mb-8 shadow-md" v-for="(student, index) in students" :key="index" inset>
            <van-cell>
                <template #title>
                    <van-image :src="Env.userPath + student.icon" fit="cover" :radius="10" width="4rem" height="4rem" />
                </template>
                <template #value>
                    <div class="flex flex-col items-end">
                        <van-tag class="mb-2">出勤 Attendance</van-tag>
                        <div class="flex flex-wrap justify-end">
                            <div class="px-2 py-1 text-white ml-1 mb-1" :class="{'bg-green-400': att.att===1, 'bg-red-600': att.att===3, 'bg-yellow-600': att.att===2, 'bg-gray-800': att.att===4}" v-for="(att, index) in student.att" :key="index">
                                {{att.date}}
                            </div>
                        </div>
                    </div>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <div class="flex justify-items-start items-start">
                        <div class="flex flex-col">
                            <span class="pb-1">{{student.showName}}</span>
                            <span class="text-gray-400 text-xs">{{student.firstName}} {{student.lastName}}</span>
                        </div>
                        <!-- <van-tag class="ml-3 mb-1" color="#7232dd" v-for="(tag, index) in student.tags" :key="index">{{tag}}</van-tag> -->
                    </div>
                </template>
                <template #value>
                    <van-tag class="mb-2">作业 Homework</van-tag>
                    <div class="py-3">
                        <van-progress :percentage="student.work/totalStar*100" :pivot-text="student.work + '/' + totalStar" pivot-color="#7232dd" color="linear-gradient(to right, #be99ff, #7232dd)" stroke-width="12px" />
                    </div>
                </template>
            </van-cell>
        </van-cell-group>
    </div>
</div>
</template>

<script>
import {
    ref,
    onMounted
} from "vue";
import {
    useStore
} from "vuex";
import {
    useRoute,
    useRouter
} from "vue-router";
import {
    Cell,
    CellGroup,
    Tag,
    Image as VanImage,
    Rate,
    Divider,
    Progress
} from "vant";
import Env from "@/logics/Envs.js";
import GetSession from "@/asyncs/GetSession.js";
import ListStudentId from "@/asyncs/ListStudentId.js";
import ListPractice from "@/asyncs/ListPractice.js";
import ListStudent from "@/asyncs/ListStudent.js";
import LoadSession from "@/asyncs/LoadClassSession.js";

export default {
    components: {
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [Tag.name]: Tag,
        [VanImage.name]: VanImage,
        [Rate.name]: Rate,
        [Divider.name]: Divider,
        [Progress.name]: Progress
    },
    setup() {
        const store = useStore();
        store.commit("showBack");
        store.commit("showMenu");
        store.commit("setPageTitle", "学生考勤");
        const route = useRoute();
        const router = useRouter();
        const year = parseInt(route.params.year);
        const month = parseInt(route.params.month);
        const students = ref([]);
        const totalStar = ref(20);
        if (route.params.stars) {
            totalStar.value = route.params.stars;
        }
        const start = new Date(route.params.year, route.params.month - 1, 1);
        const dateRange = ["", ""];
        dateRange[0] = start.toLocaleDateString();
        start.setMonth(month);
        start.setDate(0);
        dateRange[1] = start.toLocaleDateString();
        onMounted(GetSession({
            store,
            router,
            route
        }, async function () {
            const ids = await ListStudentId(true);
            const studentPractice = await ListPractice(year, month, JSON.stringify(ids));
            const studentDict = await ListStudent(JSON.stringify(ids), true);
            const sessionList = await LoadSession(route.params.classId, year, month, true);

            studentPractice.forEach(function (student) {
                const studentInfo = studentDict[student.userId];
                student.firstName = studentInfo.profile.firstName;
                student.lastName = studentInfo.profile.lastName;
                student.showName = studentInfo.profile.showName;
                student.icon = studentInfo.profile.icon;
                student.tags = studentInfo.meta.tags.system;
                if (!student.practice[year]) {
                    student.work = 0;
                } else if (!student.practice[year][month]) {
                    student.work = 0;
                } else {
                    const practiceArray = Object.values(student.practice[year][month]);
                    if (practiceArray.length) {
                        student.work = practiceArray.reduce(function (a, b) {
                            return a + b;
                        }) / 2;
                    } else {
                        student.work = 0;
                    }
                }
                student.att = [];
                sessionList.forEach(function (session) {
                    if (session.check[student.userId]) {
                        student.att.push({
                            date: session.m + "/" + session.d,
                            att: session.check[student.userId].a
                        });
                    }
                });
                students.value.push(student);
            });
            students.value.sort(function (a, b) {
                if (a.work < b.work) {
                    return 1;
                } else if (a.work > b.work) {
                    return -1;
                } else {
                    if (a.lastName < b.lastName) {
                        return -1;
                    } else if (a.lastName > b.lastName) {
                        return 1;
                    }
                    return 0;
                }
            });
        }));
        return {
            Env,
            dateRange,
            totalStar,
            students
        };
    }
}
</script>
